import React from "react";

import { Layout } from "antd";
import Header from "./header.jsx";
import Footer from "./footer.jsx";
import LocaleWrapper from "../LocaleWrapper.jsx";
import {
  getLanguageFromLocale,
  getLanguageFromCookie,
  storeLanguageAsCookie,
  defaultLocale,
  supportedLanguages
} from "../../../../../lang/index";

import "../../style.css";

const { Sider, Content } = Layout;

const sidebarWidth = 350;

const getBrowserLanguage = () => {
  const first = window.navigator.languages ? window.navigator.languages[0] : null;
  return first || window.navigator.language || window.navigator.userLanguage;
};

const tryToFindLocaleForLanguage = (language) => {
  return supportedLanguages.find((locale) => locale === language || getLanguageFromLocale(locale) === language);
};

// TODO convert viewContext to an actual context maybe? Now is is just drilled down.
function AppLayout({ viewModel, viewContext, fullscreen = false, withSidebar = false, sidebarContent = null, children }) {
  const { adminLinks } = viewModel;
  const { csrfToken, loggedInAdmin } = viewContext;

  const admin = loggedInAdmin || {};

  // Get locale hierarchy:
  // 1. The locale set on the admin.
  // 2. Language stored in a cookie (means it was set on the login page)
  // 3. Language set in the browser (mapped onto one of our supported locales if possible)
  // 4. The default locale (en-GB)
  const adminLocale = admin.locale || getLanguageFromCookie() || tryToFindLocaleForLanguage(getBrowserLanguage()) || defaultLocale;

  if (admin.locale && getLanguageFromCookie() !== admin.locale) {
    storeLanguageAsCookie(admin.locale);
  }

  const questionnaireRegex = /\/dashboard\/(\d+)\//;
  const match = window.location.pathname.match(questionnaireRegex);
  const currentQuestionnaire = match ? match[1] : undefined;

  return (
    <Layout style={{ backgroundColor: "#ffffff", minHeight: "100vh" }}>
      <LocaleWrapper adminLocale={adminLocale} csrfToken={csrfToken} questionnaire={currentQuestionnaire}>
        <Header
          adminLinksViewModel={adminLinks}
          adminLocale={adminLocale}
          adminId={admin.id}
          csrfToken={csrfToken}
          isAdminLoggedIn={admin && admin.id}
        />
        <Layout>
          {withSidebar && sidebarContent && (
            <Sider width={sidebarWidth} theme="light" style={{ overflow: "auto", height: "100vh", position: "fixed", left: 0 }}>
              {sidebarContent}
            </Sider>
          )}
          <Layout style={{ backgroundColor: "#ffffff", marginLeft: withSidebar ? sidebarWidth : 0 }}>
            <Content
              style={{
                paddingLeft: fullscreen ? 0 : 105,
                paddingRight: fullscreen ? 0 : 90,
                marginBottom: 20
              }}
            >
              {React.cloneElement(children, { viewContext: { ...viewContext, adminLocale } })}
            </Content>
          </Layout>
        </Layout>
        <Footer style={{ marginLeft: withSidebar ? sidebarWidth : 0 }} />
      </LocaleWrapper>
    </Layout>
  );
}

export default AppLayout;
