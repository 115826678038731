import React from "react";

import dayjs from "dayjs";
import { IntlProvider } from "react-intl";

import "dayjs/locale/nl";
import "dayjs/locale/nl-be";
import "dayjs/locale/en";
import "dayjs/locale/en-gb";
import "dayjs/locale/es";
import "dayjs/locale/fr";
import "dayjs/locale/it";
import "dayjs/locale/de";
import "dayjs/locale/he";
import "dayjs/locale/pl";
import "dayjs/locale/ar";
import "dayjs/locale/sv";
import "dayjs/locale/tr";
import "dayjs/locale/da";
import "dayjs/locale/ro";
import "dayjs/locale/cs";
import "dayjs/locale/pt";
import "dayjs/locale/hr";
import "dayjs/locale/el";

import AntLocaleWrapper from "./AntLocaleWrapper.jsx";
import { getTranslationsMessagesForLanguage } from "../../../../lang/index";

const getAppLocalesForLanguage = (language, questionnaire = undefined) => getTranslationsMessagesForLanguage(language, questionnaire);

const dayJsLocale = (locale = "") => {
  const modifiedLocale = locale.toLowerCase();

  // downcasing our locales is enough, however when there are multiple zones with the same language there is often an export without zone
  // (eg: nl-nl is just nl in dayjs but nl-be does exist)
  // https://github.com/iamkun/dayjs/tree/dev/src/locale
  const mapping = {
    "en-us": "en",
    "nl-nl": "nl",
    "es-es": "es",
    "fr-fr": "fr",
    "de-de": "de",
    "it-it": "it",
    "pl-pl": "pl",
    "ar-ae": "ar",
    "tr-tr": "tr",
    "sv-se": "sv",
    "da-dk": "da",
    "ro-ro": "ro",
    "cs-cz": "cs",
    "pt-pt": "pt",
    "hr-hr": "hr",
    "el-GR": "el"
  };

  return mapping[modifiedLocale] ? mapping[modifiedLocale] : modifiedLocale;
};

const LocaleWrapper = ({ adminLocale, children, questionnaire }) => {
  dayjs.locale(dayJsLocale(adminLocale));

  return (
    <IntlProvider messages={getAppLocalesForLanguage(adminLocale, questionnaire)} locale={adminLocale} defaultLocale="en">
      <AntLocaleWrapper language={adminLocale}>{children}</AntLocaleWrapper>
    </IntlProvider>
  );
};

export default LocaleWrapper;
